export default {
  space: Array.from(Array(31).keys()).map(x => x * 5),
  breakpoints: ['768px', '1024px'],
  colors: {
    text: '#000000',
    primary: '#425FF8'
  },
  fonts: {
    heading: 'IBM Plex Serif, serif',
    body: 'IBM Plex Mono, monospace'
  },
  fontWeights: {
    body: 400,
    heading: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  sizes: {
    container: 1125,
    largeContainer: 1340,
  },
  text: {
    heading: {
      fontSize: [20, 24, 28]
    }
  },
  buttons: {
    primary: {
      px: [5, 5, 7],
      py: [2, 2, 3],
      outline: 'none',
      cursor: 'pointer',
      fontSize: [16, 16, 20],
      color: 'text',
      bg: '#B6BBB7',
      borderRadius: 0,
      borderTop: '3px white solid',
      borderLeft: '3px white solid',
      borderRight: '3px #252724 solid',
      borderBottom: '3px #252724 solid',
    },
    small: {
      variant: 'buttons.primary',
      p: [1, 1],
      fontSize: 16,
      width: '100%',
    }
  },
  forms: {
    label: {
      mb: 1,
      fontSize: 12,
    },
    input: {
      outline: 0,
      bg: 'white',
      borderRadius: 0,
      border: 0,
      mb: 4,
      fontSize: 14,
      lineHeight: '16px',
      color: 'black',
      borderTop: '1px solid #979797',
      borderLeft: '1px solid #979797',
      boxShadow: 'inset 1px 1px 0 #000000'
    },
    select: {
      variant: 'forms.input',
      '+ svg': {
        position: 'absolute',
        top: '30px',
        right: 0,
        fill: 'black',
        m: 0
      }
    }
  },
  styles: {
    root: {
      fontSize: [16, 16, 18],
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    p: {
      m: 0
    },
    strong: {
      fontWeight: 'heading',
    },
    blockquote: {
      m: 0,
      pl: 4,
      fontWeight: 'heading',
      borderLeft: '5px solid #858585'
    },
    ul: {
      pl: 6,
      fontSize: [null, 22],
      listStyle: 'none'
    },
    li: {
      position: 'relative',
      ':not(:last-child)': {
        mb: 5
      },
      ':before': {
        position: 'absolute',
        top: 1,
        left: -7,
        content: `""`,
        verticalAlign: 'middle',
        display: 'inline-block',
        width: '15px',
        height: '15px',
        bg: 'black'
      }
    }
  },
}
